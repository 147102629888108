.editorfreeform textarea {
  font-size: 1rem;
  line-height: 1.7rem;
}

.editorfreeform .line {
  border-bottom: 1px solid #ddd;
}

.editorfreeform h6 {
  margin: .5em 0 0;
  font-size: 1em;
  font-weight: bold;
}