.navbarPublic {
  background: var(--purple-gradient);
  font-weight: bold;
  z-index: 10;
}

.navbarPublic.navbarPublic-overlay {
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  .navbarPublic.navbarPublic-overlay {
    background: #56137C00;
    transition: background .2s ease-in-out;
  }
  
  .navbarPublic.navbarPublic-overlay.expanded {
    background: #56137C;
  }
}

/* .navbarPublic .mobileBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background: var(--purple-gradient);
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.navbarPublic.expanded .mobileBackground {
  opacity: 1;
} */

.navbarPublic .navContent {
  color: white;
  font-size: 1.2em;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .navbarPublic.navbarPublic-overlay .navContent {
    margin-top: .75em;
    align-items: flex-start;
  }
}

.navbarPublic .navContent > * {
  color: inherit;
  margin-left: 1.5em;
}

.navbarPublic .navContentMobile {
  color: white;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: stretch;
  max-height: 0;
  transition: max-height .3s ease-out, margin-top .3s ease-in-out;
  overflow: hidden;
}

.navbarPublic.expanded .navContentMobile {
  transition: max-height .3s ease-in, margin-top .3s ease-in-out;
  max-height: 10em;
  margin-top: 1rem;
}

.navbarPublic .navContentMobile a {
  color: inherit;
  padding: .25em 0 .25em 39px;
}

.navbarPublic .navContent .wordmark {
  margin: 0 auto 0 0;
}

.navbarPublic a:hover,
.navbarPublic a.active {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.navbarPublic .navContentMobile a:hover,
.navbarPublic .navContentMobile a.active {
  text-decoration: none;
  background: #fff4;
  border-radius: .35em;
}

.navbar-login {
  position: absolute;
  top: 3.5em;
  right: 6em;
  background: #fffd;
  backdrop-filter: blur(8px);
  border: 1px solid #0003;
  border-radius: .5em;
  box-shadow: 0 1em 2em #2132;
  z-index: 999;
}

.navbar-login-overlay {
  top: 4em;
}