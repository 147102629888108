.fadeInDelay {
  animation: fadeIn .5s ease-in-out 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
