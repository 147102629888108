.play-controls {
  position: fixed;
  bottom: 2rem;
  left: 0;
  right: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  pointer-events: none;
}

.play-controls * {
  pointer-events: all;
}

.play-controls .spinner-border {
  width: 1.25em;
  height: 1.25em;
  margin-top: .14em;
}

.play-controls.big {
  bottom: 4rem;
}

.play-controls.big .btn-dot {
  width: 72px;
  height: 72px;
}

.play-controls.big img {
  width: 64px;
  height: 64px;
}

.play-controls.big .spinner-border {
  width: 40px;
  height: 40px;
  margin-top: 4px;
}

.scene-progress {
  position: fixed;
  bottom: 2rem;
  left: 40vw;
  right: 40vw;
  height: 1rem;
  transition: background .5s ease-in-out;
  background: transparent;
}

.scene-progress.playing {
  background: #dadcde;
}
