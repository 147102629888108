.editorimport {
  font-size: 0.8rem;
}

.editorimport textarea {
  font-size: 1rem;
  line-height: 1.7rem;
}

.editorimport p {
  border-left: 1px solid #ccc;
}

.editorimport .line {
  border-bottom: 1px solid #ddd;
}

.editorimport h6 {
  margin: .25em 0;
  font-size: 1em;
  font-weight: bold;
}