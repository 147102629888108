.msg-card-body {
  padding: .8em;
  border-left: 6px solid var(--secondary);
}

.msg-quote-voice {
  font-weight: bold;
  color: var(--secondary);
}

.msg-quote-line {
  font-family: Merriweather, serif;
}

.msg-quote-dir {
  font-style: italic;
}

.msg-quote-dir::before {
  content: '>';
  color: var(--secondary);
  font-style: normal;
}

.msg-quote-noteTitle {
  font-size: .9em;
  font-weight: bold;
  color: var(--note-color);
}

.msg-quote-noteBody {
  font-size: .9em;
  color: var(--secondary);
  border-left: 2px solid var(--note-color);
  padding-left: .5em;
}

.msg-quote-id {
  font-family: var(--font-family-monospace);
}

.msg-quote-fix {
  color: var(--success);
  --note-color: var(--secondary);
}