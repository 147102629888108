.editornote {
  margin: 1em auto;
  display: flex;
  align-items: flex-start;
}

.editornote-pane {
  margin-left: .5em;
  border-left: 2px solid var(--note-color);
  padding-left: .5em;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.editornote-pane > * {
  margin-bottom: .75em;
}

.editornote-pane > :last-child {
  margin-bottom: 0;
}

.editornote-pane > textarea {
  flex-grow: 1;
}

.tab-comments-content {
  background: #ffe880;
}