.home-header {
  position: relative;
}

.home-header a {
  text-decoration: none;
}

.home-header-content {
  position: absolute;
  top: 2rem;
  bottom: 3rem;
  left: 0;
  right: 0;
}

/*
.hover-highlight {
  transition: all .5s ease-in-out;
  border-radius: 1em;
  padding: .75em 0;
  box-shadow: 0 0em .2em var(--paper);
}

.hover-highlight:hover {
  transition: all .3s ease-out;
  background-color: var(--paper-light);
  box-shadow: 0 1em 2em #e9e6e1;
}
*/

.golink {
  display: flex;
  align-items: center;
}

.golink a {
  color: var(--info);
  text-decoration: underline var(--paper);
  transition: text-decoration-color .2s ease-in-out;
}

.golink:hover a {
  text-decoration-color: var(--info);
  transition: text-decoration-color .1s ease-out;
}

.golink img {
  height: 1.5em;
  margin-left: .1em;
  transition: margin-left .3s ease-in-out;
}

.golink:hover img {
  margin-left: .3em;
  transition: margin-left .1s cubic-bezier(0, 0.5, 0.5, 1);
}

.allShows {
  background: linear-gradient(120deg, #B3A7EF, #ebbbf7);
  color: #23162e;
  height: 100%;
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: color .1s ease-in-out, font-size .2s ease-in-out;
}

.allShows:hover {
  color: #44265c;
}