.editoraudio .control-column {
  position: sticky;
  top: 8em;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}

.editoraudio .control-column > button,
.editoraudio .control-column > .btn-group {
  margin-bottom: 1em;
}

.editoraudio .control-column .edit-filename {
  font-size: .7em;
  color: #ddd;
  cursor: pointer;
}

.editoraudio .audio-line {
  margin: .5em 0;
  display: flex;
  align-items: flex-end;
}

.editoraudio.recording {
  color: #888;
}

.editoraudio .audio-line .data {
  min-width: 4rem;
  text-align: right;
  margin-right: 1rem;
  font-family: monospace;
  font-size: 1.1em;
  color: #bbb;
}

.editoraudio .audio-line .data.bit {
  min-width: 1rem;
}

.editoraudio .audio-line .data.line-current {
  color: var(--red);
  font-size: 2em;
  line-height: .8em;
} 

.editoraudio .audio-line .line-voice {
  color: var(--yellow);
  display: block;
  font-size: 0.7em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .05em;
  padding-left: .5em;
}

.editoraudio .audio-line.editor-current {
  color: black;
}