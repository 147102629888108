.catalog {
  list-style: none;
  padding: 0;
}

.catalogDivider {
  margin: .2em 0;
  display: flex;
}

.catalog .catalogDividerLine {
  margin: 1em 33% 1em .5em;
  border-top: 1px solid var(--gray-light);
  flex-grow: 1;
}

.catalogItem {
  margin: .2em 0;
  display: flex;
}

.catalogItem.active {
  font-weight: bold;
}

.catalogPill {
  width: 4px;
  border-radius: 2px;
  background: transparent;
  transition: background .2s ease-in-out;
}

.catalogItem.active .catalogPill,
.catalogItem.active:hover .catalogPill {
  background: var(--gray-dark);
}

.catalogItem:hover .catalogPill {
  background: var(--secondary);
}

.catalog .catalogLink {
  display: block;
  padding: .2em .5em;
  color: black;
  text-decoration: none;
  flex-grow: 1;
}
