:root {
  --paper: #f9f7f5;
  --paper-light: #faf9f7;
  --paper-dark: #f7f5f3;
  --gray-light: #A4A9B3;
  --purple-gradient: linear-gradient(to right, #7F0F7F, #49139D);
}

.define { --note-color: #13BD76; --note-color-light: #D3F1DF }
.dramaturg { --note-color: #249EE9; --note-color-light: #D5EAF4 }
.production { --note-color: #B959D6; --note-color-light: #EEE6F2 }
.content { --note-color: #A4A9B3; --note-color-light: #E3E5E8 }
.note-user { --note-color: #EDB800; --note-color-light: #FBECC0 }
.char-greek { --char-color: #3555bd; }
.char-trojan { --char-color: #a0852e; }
.char-sicily { --char-color: #3a838d; }
.char-bohemia { --char-color: #af4a39; }
.char-capulet { --char-color: #22856c; }
.char-montague { --char-color: #af3960; }
.char-escalus { --char-color: #a0852e; }

body {
  font-family: Inter, sans-serif;
  background: var(--paper);
}

.font-serif {
  font-family: Merriweather, serif;
}

*[role=button] {
  cursor: pointer;
}