.script {
  padding: 8rem 1rem 8rem;
}

.recording .script {
  font-size: 1.2em;
}

@media (min-width: 768px) {
  .script {
    padding: 8rem 2rem 12rem;
  }
}
