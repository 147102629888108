.stage-col {
  padding: 0;
  background: var(--stage-image) no-repeat center/cover, linear-gradient(to bottom, #414a4e, #716489);
}

.stage-col nav {
  background: #0004;
  mix-blend-mode: color-dodge;
  padding: 2em 1em 2em 0;
  height: 5.25em;
  overflow: hidden;
  opacity: .5;
  transition: height .3s ease-out, opacity .2s ease-out;
}

.stage-col nav.recording {
  opacity: 0;
}

.stage-col nav:hover {
  opacity: .8;
}

.stage-col nav.menu-expand {
  height: 100vh;
  overflow: scroll;
  opacity: 1;
}

.menu-cell {
  flex: 0 0 auto;
  padding: 0 1em 1em;
}

.menu-cell img {
  height: 2.25em;
  width: 2.25em;
}

.scene-cell {
  flex: 0 0 auto;
  padding: .25em 1em;
  /* border-bottom: 1px solid #fff2; */
  color: #fff8;
  transition: color .2s ease-out;
}

.scene-cell.active {
  color: #fffa;
  font-weight: bold;
}

.scene-cell:hover {
  color: #fff;
  transition: color .05s ease-out;
}

.exit-cell {
  padding-bottom: 1em;
}

.exit-cell img {
  margin-right: .25em;
  opacity: .6;
  transition: opacity .2s ease-out;
}

.exit-cell:hover img {
  opacity: 1;
  transition: opacity .05s ease-out;
}

.stage-edge {
  position: fixed;
  top: -2px;
  bottom: -2px;
  left: 2px;
  padding: 0;
  pointer-events: none;
  z-index: 100;
}