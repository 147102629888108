h6 {
  color: var(--char-color);
}

.char-remove-btn,
.char-edit-btn {
  font-size: 1.2em;
  position: absolute;
  top: .25em;
  right: .25em;
  width: 1em;
  height: 1em;
  line-height: 1em;
  text-align: center;
  color: #ccc;
}

.char-remove-btn:hover,
.char-edit-btn:hover {
  color: var(--secondary);
}

.char-edit-btn {
  right: 1.25em;
}

.color-select,
.color-select:focus {
  color: var(--char-color);
}

.suggest-quote {
  display: flex;
  align-items: center;
}

.suggest-quote span {
  flex-shrink: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.suggest-quote .suggest-block {
  flex-shrink: 0;
  font-size: .7em;
  padding-left: .5em;
  margin: 0 -.5em 0 auto;
}