.showbar {
  background: linear-gradient(to bottom, #f9f7f5ff, #f9f7f5ee 50%, #f9f7f5dd 60%, #f9f7f500);
  position: fixed;
  z-index: 500;
  top: 0;
  right: 0;
  padding: 1em 2em 2em;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  pointer-events: none;
}

.showbar-center {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1em;
  color: var(--secondary);
  font-style: italic;
  transition: opacity .3s ease-in-out;
}

.showbar-center.title-hide {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.playback-track {
  margin-top: .25em;
  width: 12em;
  height: 3px;
  background: #ddd;
  border-radius: 2px;
}

.playback-fill {
  height: 100%;
  background: var(--secondary);
  border-radius: 2px;
  transition: width .25s linear;
}

.showbar-right {
  display: flex;
  align-items: center;
}

.showbar-right.recording {
  opacity: 0;
}

.showbar-right.recording:hover {
  opacity: 1;
}

.mode-indicator {
  font-size: .7em;
  font-weight: bold;
  letter-spacing: 1px;
  color: #89c2bd;
  display: block;
}

.showbar-button {
  pointer-events: all;
  margin-left: 1em;
  opacity: .2;
  transition: opacity .1s ease-in-out;
}

.showbar-button:hover,
.showbar-button.active {
  opacity: .5;
}

.showbar-panel {
  font-size: 0.8em;
  position: fixed;
  top: 2em;
  right: 2em;
  padding: 1em;
  border-radius: .6em;
  color: var(--secondary);
  background: #f9f7f5f8;
  backdrop-filter: blur(8px);
  box-shadow: 0 1em 2em 0 #00000011;
  z-index: 100;
  opacity: 0;
  transition: opacity .3s ease-out, top .3s cubic-bezier(1, 0, 1, 1);
  pointer-events: none;
}

.showbar-panel.shown {
  top: 5em;
  opacity: 1;
  transition: opacity .2s ease-out, top .4s cubic-bezier(0, 0, 0, 1);
  pointer-events: all;
}

.showbar-panel dt {
  color: var(--char-color);
}

.showbar-panel .demo-note-extra {
  text-decoration: underline;
  text-decoration-color: #888;
  text-decoration-style: dotted;
  text-decoration-thickness: 1.5px;
}

.showbar-panel .demo-note-auto {
  text-decoration: underline;
  text-decoration-color: #bbb;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
}

.showbar-panel .demo-color {
  color: var(--note-color);
}

.showbar-panel .demo-dot {
  width: .5em;
}

.showbar-panel .custom-control label {
  line-height: 2em;
}