.presents {
  display: block;
  margin: 0 1em;
  color: #ffffffaa;
  font-size: .8em;
  text-transform: uppercase;
  letter-spacing: .2em;
}

.play-button {
  padding-left: .5em;
}