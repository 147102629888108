.graph-canvas {
  display: flex;
  align-items: flex-end;
}

.graph-bar {
  width: 1em;
  flex-grow: 1;
  background-color: var(--info);
  margin: 0 1px;
  transition: width .1s ease-in-out;
  border-radius: 2px 2px 0 0;
}

.graph-bar:hover {
  width: 2em;
  white-space: nowrap;
}

.graph-value {
  display: none;
  font-size: .3em;
  text-align: center;
  line-height: 1.5em;
  margin: 0 2px;
  color: white;
  font-weight: bold;
  word-wrap: break-word;
}

.graph-bar:hover .graph-value {
  display: block;
}

.graph-axisline-x {
  height: 2px;
  border-radius: 2px;
  background: #aaa;
  margin-top: 1px;
}

.graph-labels {
  display: flex;
  justify-content: space-between;
  color: var(--secondary);
  font-size: .8em;
  text-transform: uppercase;
}