.shatter {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000000;
  pointer-events: none;
}

.shatter-preload {
  position: fixed;
  top: 0;
  left: 0;
  height: 1px;
  width: 1px;
  z-index: 1000000;
  pointer-events: none;
  opacity: .05;
}