.viewTrackRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.viewTrackLine {
  height: 2em;
  width: 100%;
  display: flex;
  background: var(--purple-gradient);
  border-radius: .25em;
}

.viewTrackCell {
  height: 100%;
  border-right: 2px solid var(--paper);
  background: #f9f7f5dd;
  flex-grow: 1;
}

.viewTrackCell.played {
  background: none;
}

.viewTrackCell:first-child {
  border-radius: .25em 0 0 .25em;
}

.viewTrackCell:last-child {
  border-radius: 0 .25em .25em 0;
  border-right: none;
}