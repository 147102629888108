.loginForm {
  width: 16em;
  font-weight: normal;
}

.loginForm .fieldError {
  border-color: var(--danger);
  background: #faedeb;
}

.loginForm .fieldSuccess {
  border-color: var(--success);
  background: #ebfaee;
}

.loginForm .errorMsg {
  color: var(--danger);
  font-size: .85em;
  margin: .1em .3em .5em;
}

.loginForm .errorMsg.benign {
  color: var(--secondary);
  font-size: .75em;
  margin: .1em .3em .5em;
}

.loginForm.done .fieldError,
.loginForm.done .fieldSuccess {
  border-color: revert;
  background: revert;
}

.loginForm.done .errorMsg {
  display: none;
}

.loginForm .signInBtn {
  width: 50%;
  margin: .5em 25%;
}

.loginForm .createAccount {
  display: block;
  /* font-size: .9em; */
  color: var(--info);
  text-align: center;
  width: auto;
}

.loginForm .accountPromo {
  color: var(--secondary);
  font-size: .9em;
  line-height: 1.3;
}

.loginForm .promoItem {
  display: flex;
  align-items: center;
  margin: .75em 0 0;
}

.loginForm .accountPromo img {
  opacity: .5;
}

.loginForm .accountPromo p {
  margin: 0 0 0 .5em;
}