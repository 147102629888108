.script h6, .editor h6 {
  margin: 2em 0 0.5em;
  font-size: 1em;
  font-weight: 600;
}

.block {
  position: relative;
}

.line-container {
  display: block;
  position: relative;
  padding-left: 1em;
  transition: all .5s cubic-bezier(0.5, 0.5, 0, 1);
}

.block, .line-container, .block h6 {
  pointer-events: none;
}

.line-container * {
  pointer-events: auto;
}

.line {
  display: block;
  font-family: Merriweather, serif;
  line-height: 1.8
}

.block.script-italic .line {
  font-style: italic;
}

@media (min-width: 768px) {
  .line.limit-length {
    max-width: 60%;
  }
}

.user-noted {
  background: #FFE98B;
  width: fit-content;
  padding: 0 .25em;
  margin: 0 -.25em;
  border-radius: .25em;
}

.line-indicator {
  position: absolute;
  left: 0;
  bottom: 0;
  width: .25em;
  height: 0;
  background: #444;
  transition: all .5s cubic-bezier(0.5, 0.5, 0, 1);
}

.line-container.current .line-indicator {
  top: 0;
  bottom: auto;
  height: 100%;
}

div.line-container:nth-of-type(1) .line-indicator,
div.line-container:nth-of-type(1) .line-indicator-track {
  border-top-left-radius: .25em;
  border-top-right-radius: .25em;
}

div.line-container:nth-last-of-type(1) .line-indicator,
div.line-container:nth-last-of-type(1) .line-indicator-track {
  border-bottom-left-radius: .25em;
  border-bottom-right-radius: .25em;
}

.line-indicator-track {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: .25em;
  background: none;
  transition: all .5s cubic-bezier(0.5, 0.5, 0, 1);
  cursor: pointer;
}

.in-block .line-indicator-track {
  background: #ddd;
}

.block:hover .line-indicator-track {
  width: .5em;
  background: #ddd;
}

.block:hover .line-indicator {
  width: .5em;
}

.block:hover .line-indicator-track:hover {
  width: .75em;
  transition: all .5s cubic-bezier(0.5, 0.5, 0, 1), background-color .1s ease-out;
  background: #888;
}

.block:hover .line-indicator:hover {
  width: .75em;
}

.scrollpoint {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
}

.stagedir {
  font-style: italic;
  margin: 1em 1.5em;
  line-height: 2em;
}

.block .stagedir {
  display: block;
  margin: .5em 1.5em;
}

.note-inline {
  text-decoration-line: underline;
  text-decoration-color: var(--note-color);
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
  margin: 0 -.15em;
  padding: .15em;
  border-radius: .15em;
  cursor: pointer;
  transition: all .1s ease-out;
}

.note-inline.note-extra {
  text-decoration-style: dotted;
  /* text-decoration-thickness: 3px; */
}

.recording .note-inline.note-extra {
  text-decoration: none;
}

.note-inline.note-focused {
  background: var(--note-color);
  color: white;
  transition: all .02s ease-out;
}

.line-noteadd {
  position: absolute;
  left: 60%;
  top: .25em;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.line-container:hover .line-noteadd {
  opacity: .2;
}

.line-container .line-noteadd:hover {
  opacity: .5;
  transition: opacity .1s ease-in-out;
}