.show-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: var(--stage-image) top / cover no-repeat, #444;
  padding: 4em 1em 1em;
  border-bottom: 1px solid #ddd;
}

.show-nav select {
  background: #00000044;
  color: white;
  border-color: #ffffff66;
  transition: color 5s ease-in-out;
}

@media (min-width: 768px) {
  .show-nav {
    display: none;
    position: fixed;
    top: 1em;
    left: 1em;
    width: 14em;
    transition: opacity 5s ease-in-out;
    background: none;
    border-bottom: none;
  }
  
  .show-nav select {
    background: #00000022;
    color: white;
    transition: color 5s ease-in-out;
  }
  
  .show-nav.seen { opacity: 0.2; }
  .show-nav.seen select { color: transparent; }
  .show-nav.seen.recording { opacity: 0; }
  
  .show-nav.seen:hover {
    opacity: 1;
    transition: opacity .1s ease-in-out;
  }
  .show-nav.seen:hover select {
    color: white;
    transition: color .1s ease-in-out;
  }
}
