.btn-dot {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  box-shadow: 0 0 .125em .125em #FFFFFFCC;
  padding: 0;
}

.btn-dot.btn-lg {
  width: 48px;
  height: 48px;
}