.note {
  width: 100%;
  margin: 0 auto;
  color: #444;
  font-size: 0.85em;
  max-height: 0;
  overflow: hidden;
  transition: all .5s ease-out;
}

.note.note-reveal {
  max-height: 15em;
  opacity: 1;
  transition: all .5s ease-in;
}

.note.long.note-reveal {
  max-height: 30em;
}

@media (max-width: 767px) {
  .note {
    margin-top: 0 !important;
  }
}

@media (min-width: 768px) {
  .note {
    width: 33%;
    margin: -.5em auto 1em;
    color: #666;
    float: right;
    clear: both;
    opacity: 0;
    transition: all 5s ease-in-out;
  }

  .note.note-reveal {
    max-height: 20em;
    transition: all 1s ease-in-out;
  }

  .note.note-reveal.delay-1 { transition-delay: 500ms; }
  .note.note-reveal.delay-2 { transition-delay: 800ms; }
  .note.note-reveal.delay-3 { transition-delay: 1000ms; }
  .note.note-reveal.delay-4 { transition-delay: 1200ms; }
  .note.note-reveal.delay-5 { transition-delay: 1300ms; }
  .note.note-reveal.delay-6 { transition-delay: 1400ms; }
  .note.note-reveal.delay-7 { transition-delay: 1500ms; }

  .note.note-quick.note-quick {
    transition: all .7s ease-out, opacity .3s ease-in-out;
    transition-delay: 0;
  }

  .note.long.note-reveal {
    max-height: 50em;
  }
}



.note-header {
  width: 100%;
  font-weight: 700;
  color: var(--note-color);
  margin-bottom: -2px;
  border-bottom-left-radius: 2px;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color .1s ease-out;
}

.note-collapsed .note-header {
  border-bottom-color: var(--note-color);
  transition: border-bottom-color .3s ease-in-out .4s;
}

.note-body {
  width: 100%;
  margin: .25em 0 1em;
  padding: 0 .5em;
  border-left: 2px solid var(--note-color);
  border-radius: 0 .5em .5em 2px;
  transition: background .2s ease-in-out, max-height .5s ease-in-out;
  overflow: hidden;
  max-height: 15em;
}

.note.long .note-body {
  max-height: 30em;
}

.note-focused .note-body {
  background: var(--note-color-light);
  color: #333;
}

.note.note-collapsed .note-body {
  max-height: 0;
}



.note-pin {
  width: 1em;
  height: 1em;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  background: url(/assets/icon-pin.svg) no-repeat;
}

.note-pin.pinned {
  opacity: .1;
}

.note:hover .note-pin {
  opacity: .2;
}

.note:hover .note-pin.pinned {
  opacity: .5;
}

.note .note-pin.note-pin:hover { /* specificity */
  opacity: .5;
  transition: opacity .1s ease-in-out;
  background: url(/assets/icon-pin.svg) no-repeat;
}

.note .note-pin.pinned:hover {
  background: url(/assets/icon-unpin.svg) no-repeat;
}

@media (max-width: 767px) {
  .note-pin {
    display: none;
  }
}



.note-disclosure {
  width: 1.25em;
  height: 1.25em;
  opacity: .1;
  transition: opacity .2s ease-in-out, transform .2s ease-out;
  background: url(/assets/icon-undisclose.svg) no-repeat;
  transform: rotate(0deg);
}

.note-collapsed .note-disclosure {
  opacity: .3;
  transform: rotate(90deg);
}

.note:hover .note-disclosure {
  opacity: .1;
}

.note.note-collapsed:hover .note-disclosure {
  opacity: .5;
}

.note .note-disclosure.note-disclosure:hover { /* specificity */
  opacity: .5;
  transition: opacity .1s ease-in-out, transform .2s ease-out;
}

@media (max-width: 767px) {
  .note-disclosure {
    display: none;
  }
}



.note .video-button {
  margin-top: -.3em;
  border-radius: 3em;
  padding-right: 1em;
  margin-bottom: 2em;
}

.note .video-button img {
  height: 1.5em;
  margin: 0 .25em .1em -.1em;
}

.fake-note {
  color: var(--note-color);
  font-size: 0.8em;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  margin: .5em 0 0;
}

.fake-note-line {
  margin-left: 0.5em;
  margin-right: 50%;
  flex-grow: 1;
  height: 2px;
  background: var(--note-color);
  /* background: linear-gradient(to right, var(--note-color) 50%, white) */
}
