.lightbox-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000066;
  backdrop-filter: blur(4px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5vh 5vw;
}

.lightbox {
  position: relative;
  background: white;
  padding: 3em 2em 2em;
  border-radius: 1em;
  box-shadow: 0 1em 2em #00000066;
  max-height: 90vh;
}

.lightbox .close {
  position: absolute;
  top: .4em;
  right: .4em;
}
