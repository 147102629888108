.editortextblock {
  display: flex;
  align-items: flex-start;
}

.editortextblock button {
  font-weight: bold;
  min-width: 2em;
  padding: .25em;
}

.editortextblock .block-controls button {
  width: 2em;
}
