footer {
  background: #23162e;
  color: #8c6c9d;
  padding: 4em 0;
  font-size: 0.9em;
}

footer h6 {
  font-weight: bold;
}

footer .socialLink {
  height: 32px;
  width: 32px;
  margin-right: .5em;
}

footer .previewBanner {
  background-color: #372144;
  border-radius: 1em;
}